@import '../../../styles/basics';

.breadcrumbs {
  font-size: 15px;

  &--desktop {
    display: none;
  }

  &--white {
    color: $white;
    text-shadow: 0 0 10px $black-50;
  }

  &__separator {
    margin: 0 8px;
    font-size: 12px;
  }

  &__link {
    text-decoration: underline;
  }

  @include media-breakpoint-up(md) {
    &--desktop {
      display: block;
    }
  }
}
