@import '../../styles/gifts/sass/basics';

$popup-max-width: 592px;

.region-popup {
  padding: 4px;
  &__title {
    font-weight: bold;
    color: $green-800;
    font-size: 22px;

    @media (min-width: $screen-sm-min) {
      font-size: 30px;
    }
  }

  &__description {
    font-size: 18px;
  }

  @media (min-width: $popup-max-width - 40px) {
    max-width: $popup-max-width;
    margin-left: auto;
    margin-right: auto;
  }

  :global {
    .btn-block {
      font-size: 20px;
      text-transform: none;
      box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.5);
    }

    .btn-primary {
      font-weight: bold;
    }
  }

  @media (min-width: $screen-xs-min) {
    &__buttons {
      display: flex;

      :global {
        a.btn-block {
          margin-top: 16px;
        }

        button {
          margin-top: 16px;
          margin-right: 24px;
        }
      }
    }
  }
}
