/* Common, default styles for the notification box */
@import '../../../styles/basics';

.alert {
  &__container {
    top: 60px;
  }
  :global {
    .Toastify__toast {
      line-height: $line-height-large;
      font-size: 14px;
      font-weight: $font-weight-regular;

      a {
        color: $white;
        text-decoration: underline;
      }

      p {
        color: $white;
        font-size: 14px;
        font-weight: $font-weight-regular;
      }
    }

    .Toastify__toast--success {
      background: $green-800;
      color: $white;
    }

    .Toastify__toast--error {
      background: $red-800;
      color: $white;
    }

    .Toastify__toast--warning {
      background: $yellow-800;
      color: $white;
    }

    .Toastify__toast--info {
      background: $blue-800;
      color: $white;
    }

    .Toastify__toast-icon {
      display: none;
    }
  }

  @include media-breakpoint-up(sm) {
    &__container {
      top: 80px;
    }
  }

}
