@import '../../styles/gifts/sass/basics';

$popup-max-width: 592px;

.clear-basket-popup {
  padding: 4px;
  &__title {
    font-weight: bold;
    color: $green-800;
    font-size: 22px;

    @media (min-width: $screen-sm-min) {
      font-size: 30px;
    }
  }

  &__buttons {
    display: flex;

    :global {
      a.btn-block {
        margin-top: 16px;
      }

      button {
        margin-top: 16px;
        margin-right: 24px;
      }
    }
  }
}
