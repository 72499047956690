@import '../../../styles/basics';
@value activism-page-type from '../../../styles/page-type.module.scss';

.accessibility-skip {
  position: absolute;
  display: inline-block;
  top: 3px;
  right: 100%;
  background-color: $turquoise-1000;
  padding: 8px 12px;
  box-shadow: 0 0 3px 1px $slate-1200;
  color: $white;
  font-size: 18px;
  font-weight: bold;

  &__showing:focus-visible {
    right: auto;
    z-index: 20000000;
    left: 3px;
  }

  &:hover,
  &:focus {
    color: $white;
  }
  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}

.activism-page-type .accessibility-skip {
  top: -86px;
}
