@import '../../styles/gifts/sass/basics';

.modal-open {
  overflow: hidden;
}
.modal {
  display: none;
  overflow: initial;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $navbar-zindex + 10;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: 100%;
  margin: auto 10px;

  &:focus-visible,
  &:focus {
    outline: none;
  }
}

.modal-content {
  position: relative;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  outline: 0;
  padding-top: 50px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $navbar-zindex + 9;
  background-color: $white;
  transition: background-color 0.3s;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;
}

.modal-backdrop.fade {
  background: rgba(255,255,255,0);
}

.modal-backdrop.in {
  background: rgba(255,255,255,0.9);
}

.modal-body {
  position: relative;
  padding: 10px;

  @media (min-width: $screen-xs-min) {
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}

.close {
  position: absolute;
  cursor: pointer;
  height: 32px;
  width: 32px;
  margin: 24px;
  z-index: 100;
  top: 0;
  right: 0;
  appearance: none;
  background: none;
  padding: 7px;
  border-radius: 50%;
  border: 1px solid $black;
  transition: border-color 0.2s, background-color 0.2s;
  line-height: 16px;

  svg {
    fill: $black;
    width: 16px;
    height: 16px;
    vertical-align: initial;
  }

  &:hover {
    background-color: $slate-1200;
    border-color: $slate-1200;

    svg {
      fill: $white;
    }
  }
}
