@import '../../../../styles/basics';

.tag-icon {
  display: inline-block;
  font-size: 11px;
  line-height: 18px;
  padding: 3px 5px;
  width: 32px;

  &__video {
    background: url('/static/icons/video.svg') no-repeat left center;
    background-size: cover;
  }

  @include media-breakpoint-up(md) {
    font-size: 14px;
    padding: 5px 10px;
    width: 42px;
  }
}

